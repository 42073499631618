/* styles.css */
.radialGradientBackground {
    background: radial-gradient(
      119.46% 119.46% at 50.12% 119.46%,
      rgb(53, 118, 212),
      rgb(20, 20, 60) 100%
    );
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
  